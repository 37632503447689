import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import {
  Box,
  Anchor,
  Button,
  Image,
  Input,
  Label,
  Icon,
  Text,
  Heading,
} from "../components/elements";
import { Form as BootstrapForm } from "react-bootstrap";
import { LabelField, LabelTextarea } from "../components/fields";
import { CardLayout, CardHeader } from "../components/cards";
import Breadcrumb from "../components/Breadcrumb";
import PageLayout from "../layouts/PageLayout";
import data from "../data/master/productUpload.json";
import Summary from "./CouponSummarySection";
import { discountTyoe } from "../utils/couponConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminDropdownProductsAction,
  getAdminProductsAction,
  getDropdownCategories,
} from "../store/actions/adminProduct";
import { getAdminUsersAction } from "../store/actions/adminUsers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  createCouponAction,
  getAdminCouponDetailAction,
  updateAdminCouponAction,
} from "../store/actions/adminCoupon";
import moment from "moment-timezone";
import { useQuery } from "../hooks/useQuery";
import JoditEditor from "jodit-react";
import FormikJoditEditor from "../components/editor/formikJoditEditor";

const csegments = [
  { label: "Customers who haven't purchased", value: "whoHaveNotPurchased" },
  { label: "Customers who haven't purchased", value: "whoHaveNotPurchased" },
  {
    label: "Customers who have purchased more than once",
    value: "whoHavePurchasedMoreThanOnce",
  },
  {
    label: "Abandoned checkouts in the last 30 days",
    value: "abandonedCheckoutsInTheLast30Days",
  },
  { label: "Email subscribers", value: "emailSubscribers" },
  {
    label: "Customers who have purchased at least once",
    value: "whoHavePurchasedAtLeastOnce",
  },
];
const CouponSchema = Yup.object().shape({
  // discountCode: Yup.string().required('Required'),
  // // discountValue: Yup.number().required('Required'),
  // startDate: Yup.date().required('Required'),
  // startTime: Yup.date().required('Required'),
});

const amountOffProducts = {
  type: "amountOffProducts",

  name: "",

  method: {
    name: "discountCode",
    discountCode: "", // if name is  ‘discountCode’
    title: "", // if name is ‘automaticDiscount’
  },

  discountValue: {
    type: "percentage",
    percentage: "", // if  type is ‘percentage’
    amount: "", // if  type is ‘fixedAmount’
    appliesTo: "specificCollection",
    collections: [], // if appliedTo is ‘specificCollection’ but dont use this now
    products: [], // if appliedTo is ‘specificProduct’ & should be _id of products
  },

  onlyApplydiscountOncePerOrder: false, // if discountValue.type is ‘fixedAmount’

  minPurchaseRequirements: {
    name: "noMinReq",
    // ‘noMinReq’ only if  method is ‘ discountCode’
    value: "", // if ‘minPurchaseAmt’ & ‘minQuantityOfItem’ then add value else ‘’
  },

  // When  method is ‘discountCode’
  customerEligibility: {
    name: "allCustomers",
    value: [],
  },

  // When  method is ‘discountCode’
  maxDiscountUses: {
    limitNumberOfTimesThisDiscountCanBeUsed: {
      checked: false,
      value: "",
    },
    limitToOneUsePerCustomer:false
    // limitToOneUsePerCustomer: false,
  },

  combinations: [],

  activeDates: {
    startDate: "",
    endDate: "",
  },

  termnconditions: "",
};

const BuyXGetY = {
  type: "buyXgetY",

  name: "",

  method: {
    name: "discountCode",
    discountCode: "", // if name is  ‘discountCode’
    title: "", // if name is ‘automaticDiscount’
  },

  customerBuys: {
    name: "minQuantityOfItem",
    value: "",
    anyItemsFrom: "specificProduct",
    products: [], // if  anyItemsFrom ‘specificProducts’
    collections: [], // if  anyItemsFrom ‘specificCollections’
  },

  customerGets: {
    quantity: "",
    anyItemsFrom: "specificProduct",
    products: [], // if  anyItemsFrom ‘specificProducts’
    collections: [], // if  anyItemsFrom ‘specificCollections’
  },

  atDiscountValue: {
    percentage: {
      checked: true,
      value: "",
    },
    amtOffEach: {
      checked: false,
      value: "",
    },
    Free: false,
  },

  maxNumberOfUse: {
    checked: false,
    value: "",
  },

  // When  method is ‘discountCode’
  customerEligibility: {
    name: "allCustomers",
    value: [],
  },

  // When  method is ‘discountCode’
  maxDiscountUses: {
    limitNumberOfTimesThisDiscountCanBeUsed: {
      checked: false,
      value: "",
    },
    limitToOneUsePerCustomer:false
    // limitToOneUsePerCustomer: false,
  },

  combinations: [],

  activeDates: {
    startDate: "",
    endDate: "",
  },

  termnconditions: "",
};

const amountOffOrder = {
  type: "amountOffOrder",

  name: "",

  method: {
    name: "discountCode",
    discountCode: "", // if name is  ‘discountCode’
    title: "", // if name is ‘automaticDiscount’
  },

  discountValue: {
    type: "percentage",
    percentage: "", // if  type is ‘percentage’
    amount: "", // if  type is ‘fixedAmount’
    // appliesTo : 'specificCollection',
    // collections: [], // if appliedTo is ‘specificCollection’ but dont use this now
    // products : [] // if appliedTo is ‘specificProduct’ & should be _id of products
  },

  // onlyApplydiscountOncePerOrder : false, // if discountValue.type is ‘fixedAmount’

  minPurchaseRequirements: {
    name: "noMinReq",
    // ‘noMinReq’ only if  method is ‘ discountCode’
    value: "", // if ‘minPurchaseAmt’ & ‘minQuantityOfItem’ then add value else ‘’
  },

  // When  method is ‘discountCode’
  customerEligibility: {
    name: "allCustomers",
    value: [],
  },

  // When  method is ‘discountCode’
  maxDiscountUses: {
    limitNumberOfTimesThisDiscountCanBeUsed: {
      checked: false,
      value: "",
    },
    limitToOneUsePerCustomer:false
    // limitToOneUsePerCustomer: false,
  },

  combinations: [],

  activeDates: {
    startDate: "",
    endDate: "",
  },

  termnconditions: "",
};

const freeShipping = {
  type: "freeShipping",

  name: "",

  method: {
    name: "discountCode",
    discountCode: "", // if name is  ‘discountCode’
    title: "", // if name is ‘automaticDiscount’
  },

  // onlyApplydiscountOncePerOrder : false, // if discountValue.type is ‘fixedAmount’

  minPurchaseRequirements: {
    name: "noMinReq",
    // ‘noMinReq’ only if  method is ‘ discountCode’
    value: "", // if ‘minPurchaseAmt’ & ‘minQuantityOfItem’ then add value else ‘’
  },

  // When  method is ‘discountCode’
  customerEligibility: {
    name: "allCustomers",
    value: [],
  },

  // When  method is ‘discountCode’
  maxDiscountUses: {
    limitNumberOfTimesThisDiscountCanBeUsed: {
      checked: false,
      value: "",
    },
    limitToOneUsePerCustomer:false
    // limitToOneUsePerCustomer: false,
  },

  combinations: [],

  activeDates: {
    startDate: "",
    endDate: "",
  },

  termnconditions: "",
};

export default function ProductUpload() {
  const { categories, products, productList, users, loading, meta1 } =
    useSelector((state) => ({
      categories: state.adminProduct.adminCategories?.dropdownCategories,
      products: state.adminProduct.adminProducts?.dropdownProducts,
      productList: state.adminProduct.adminDropdownProducts?.products,
      meta: state.adminCoupon.adminCoupons?.meta,
      users: state.adminUsers.adminUsers?.users,
      loading: state.loader.loading,
      status: state.adminUsers.status,
      apiType: state.adminUsers.apiType,
      meta1: state.adminUsers.adminUsers?.meta,
    }));
    const [isInfluencer, setIsInfluencer] = useState(false);
  const [rCode, setRCode] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);

  const [selectedProductsCustomerBuys, setSelectedProductsCustomerBuys] =
    useState([]);
  const [selectedCollectionCustomerBuys, setSelectedCollectionsCustomerBuys] =
    useState([]);

  const [selectedProductsCustomerGets, setSelectedProductsCustomerGets] =
    useState([]);
  const [selectedCollectionsCustomerGets, setSelectedCollectionsCustomerGets] =
    useState([]);
  const [selectedCustomerSegments, setSelectedCustomerSegments] = useState([]);
  const [specificCustomer, setSelectSpecificCustomer] = useState([]);
  const [statusModal, setStatusModal] = useState(false);
  const [uploadFile, setUploadFile] = useState("image upload");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { _id } = useParams();
  const para = useParams();
  const couponId = useQuery();
  const couponInitialValues =
    location?.state === "amountOffProducts"
      ? amountOffProducts
      : location?.state === "buyXgetY"
      ? BuyXGetY
      : location?.state === "amountOffOrder"
      ? amountOffOrder
      : freeShipping;
  const [initialFormValues, setInitialFormValues] =
    useState(couponInitialValues);

  const separateDateTime = (isoString) => {
    const date = new Date(isoString);
    const datePart = date.toISOString().split("T")[0];
    const timePart = date.toISOString().split("T")[1].split(".")[0];

    return {
      date: datePart,
      time: timePart,
    };
  };

  useEffect(() => {
    if (couponId.get("_id")) {
      dispatch(getAdminCouponDetailAction(couponId.get("_id")))
        .then((res) => {
          const data = res.data; // Assuming the API returns the coupon data here.
          // Determine the discount type
          let updatedValues = { ...couponInitialValues };
          const productIds = data.discountValue.products.map(
            (product) => product._id
          );
          const collectionIds = data.discountValue.collections.map(
            (collection) => collection._id
          );
          const startDateTime = separateDateTime(data.activeDates.startDate);
          const endDateTime = separateDateTime(data.activeDates.endDate);
          if (data?.isInfluencer !== undefined) {
            setIsInfluencer(data.isInfluencer);
          }

          if (data.type === "amountOffProducts") {
            updatedValues = {
              ...amountOffProducts,
              // Populate fields for amountOffProducts
              // Example:
              name: data?.name,
              startTime: startDateTime.time,
              endTime: endDateTime.time,
              setEndDate: true,
              method: data.method,
              discountValue: {
                ...data.discountValue,
                percentage:
                  data.discountValue.type === "percentage"
                    ? data.discountValue.percentage
                    : "",
                amount:
                  data.discountValue.type === "fixedAmount"
                    ? data.discountValue.amount
                    : "",
                appliesTo: data.discountValue.appliesTo,
                collections:
                  data.discountValue.appliesTo === "specificCollections"
                    ? collectionIds
                    : [],
                products:
                  data.discountValue.appliesTo === "specificProducts"
                    ? productIds
                    : [],
              },
              minPurchaseRequirements: data.minPurchaseRequirements,
              customerEligibility: data.customerEligibility,
              maxDiscountUses: data.maxDiscountUses,
              combinations: data.combinations,
              activeDates: {
                startDate: startDateTime.date,
                endDate: data.activeDates.endDate ? endDateTime.date : "",
              },
              termnconditions: data.termnconditions,
              isInfluencer:data.isInfluencer
            };
            const category =
              data.discountValue.collections &&
              data.discountValue.collections.map((item) => {
                return { label: item, value: item };
              });
            setSelectedCollections(category);
            const products =
              data.discountValue.products &&
              data.discountValue.products.map((item) => {
                return { label: item?.Name, value: item?._id };
              });
            setSelectedProducts(products);
          } else if (data.type === "buyXgetY") {
            const buysProductIds = data.customerBuys.products.map(
              (product) => product._id
            );
            const buysCollectionIds = data.customerBuys.collections.map(
              (collection) => collection._id
            );
            const getsProductIds = data.customerGets.products.map(
              (product) => product._id
            );
            const getsCollectionIds = data.customerGets.collections.map(
              (collection) => collection._id
            );
            updatedValues = {
              ...BuyXGetY,
              // Populate fields for buyXgetY
              method: data.method,
              name: data?.name,
              startTime: startDateTime.time,
              endTime: endDateTime.time,
              setEndDate: true,
              customerBuys: {
                ...data.customerBuys,
                products:
                  data.customerBuys.anyItemsFrom === "specificProducts"
                    ? buysProductIds
                    : [],
                collections:
                  data.customerBuys.anyItemsFrom === "specificCollections"
                    ? buysCollectionIds
                    : [],
              },
              customerGets: {
                ...data.customerGets,
                products:
                  data.customerGets.anyItemsFrom === "specificProducts"
                    ? getsProductIds
                    : [],
                collections:
                  data.customerGets.anyItemsFrom === "specificCollections"
                    ? getsCollectionIds
                    : [],
              },
              atDiscountValue: data.atDiscountValue,
              maxNumberOfUse: data.maxNumberOfUse,
              customerEligibility: data.customerEligibility,
              maxDiscountUses: data.maxDiscountUses,
              combinations: data.combinations,
              activeDates: {
                startDate: startDateTime.date,
                endDate: data.activeDates.endDate ? endDateTime.date : "",
              },
              termnconditions: data.termnconditions,
              isInfluencer:data.isInfluencer
            };
            const categoryCustomerBuys =
              data.customerBuys.collections &&
              data.customerBuys.collections.map((item) => {
                return { label: item, value: item };
              });
            setSelectedCollectionsCustomerBuys(categoryCustomerBuys);
            const productsCustomerBuys =
              data.customerBuys.products &&
              data.customerBuys.products.map((item) => {
                return { label: item?.Name, value: item?._id };
              });
            setSelectedProductsCustomerBuys(productsCustomerBuys);
            const categoryCustomerGets =
              data.customerGets.collections &&
              data.customerGets.collections.map((item) => {
                return { label: item, value: item };
              });
            setSelectedCollectionsCustomerGets(categoryCustomerGets);
            const productsCustomerGets =
              data.customerGets.products &&
              data.customerGets.products.map((item) => {
                return { label: item?.Name, value: item?._id };
              });
            setSelectedProductsCustomerGets(productsCustomerGets);
          } else if (data.type === "amountOffOrder") {
            updatedValues = {
              ...amountOffOrder,
              // Populate fields for amountOffOrder
              method: data.method,
              name: data?.name,
              startTime: startDateTime.time,
              endTime: endDateTime.time,
              setEndDate: true,
              discountValue: data.discountValue,
              minPurchaseRequirements: data.minPurchaseRequirements,
              customerEligibility: data.customerEligibility,
              maxDiscountUses: data.maxDiscountUses,
              combinations: data.combinations,
              activeDates: {
                startDate: startDateTime.date,
                endDate: data.activeDates.endDate ? endDateTime.date : "",
              },
              termnconditions: data.termnconditions,
              isInfluencer:data.isInfluencer
            };
          } else if (data.type === "freeShipping") {
            updatedValues = {
              ...freeShipping,
              // Populate fields for freeShipping
              method: data.method,
              startTime: startDateTime.time,
              endTime: endDateTime.time,
              setEndDate: true,
              name: data?.name,
              minPurchaseRequirements: data.minPurchaseRequirements,
              customerEligibility: data.customerEligibility,
              maxDiscountUses: data.maxDiscountUses,
              combinations: data.combinations,
              activeDates: {
                startDate: startDateTime.date,
                endDate: data.activeDates.endDate ? endDateTime.date : "",
              },
              termnconditions: data.termnconditions,
              isInfluencer:data.isInfluencer
            };
          }

          setInitialFormValues(updatedValues);
        })
        .catch((error) => {
          console.error("Error fetching coupon data:", error);
        });
    }
  }, [couponId.get("_id")]);

  useEffect(() => {
    dispatch(getDropdownCategories());
    // dispatch(getAdminProductsAction());
    dispatch(getAdminDropdownProductsAction());
    dispatch(getAdminUsersAction());
  }, []);

  const productsListing =
    productList &&
    productList?.map((item) => {
      return { label: item?.Name, value: item?._id };
    });

  const proCategory =
    categories &&
    categories.map((item) => {
      return { label: item, value: item };
    });

  const customersList =
    users &&
    users?.map((item) => {
      return {
        label: item?.email || item?.phone,
        value: item?.email || item?.phone,
      };
    });

  const getIdinStatusModal = (key) => {
    setStatusModal(true);
    // setOrderId(key)
  };

  const handleNavigate = () => {
    navigate("/coupon-list");
  };
  const handleChangeCode = (e, setFieldValue) => {
    generateCouponCode(setFieldValue);
    // setFieldValue("Discount code",rCode )
  };
  const generateCouponCode = (setFieldValue) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const length = 6;
    let couponCode = "HERB-";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      couponCode += characters.charAt(randomIndex);
    }
    setFieldValue("method.discountCode", couponCode);
    setRCode(couponCode);
    return couponCode;
  };

  const customerSegmentsList =
    csegments &&
    csegments.map((item) => {
      return { label: item.label, value: item.value };
    });

  const handleSelectProductsCustomerBuys = (data, setFieldValue) => {
    const productsId = data.map((el) => el.value);
    setFieldValue("customerBuys.products", productsId);
    setSelectedProductsCustomerBuys(data);
  };

  const handleSelectCollectionsCustomerBuys = (data, setFieldValue) => {
    const categoryId = data.map((el) => el.value);
    setFieldValue("customerBuys.collections", categoryId);
    setSelectedCollectionsCustomerBuys(data);
  };
  const handleSelectProductsCustomerGets = (data, setFieldValue) => {
    const productsId = data.map((el) => el.value);
    setFieldValue("customerGets.products", productsId);
    setSelectedProductsCustomerGets(data);
  };

  const handleSelectCollectionsCustomerGets = (data, setFieldValue) => {
    const categoryId = data.map((el) => el.value);
    setFieldValue("customerGets.collections", categoryId);
    setSelectedCollectionsCustomerGets(data);
  };
  const handleSelectProducts = (data, setFieldValue) => {
    const productsId = data.map((el) => el.value);
    setFieldValue("discountValue.products", productsId);
    setSelectedProducts(data);
  };

  const handleSelectCollections = (data, setFieldValue) => {
    const categoryId = data.map((el) => el.value);
    setFieldValue("discountValue.collections", categoryId);
    setSelectedCollections(data);
  };

  const handleSelectCustomerSegments = (data, setFieldValue) => {
    const segments = data.map((el) => el.value);
    setFieldValue("customerEligibility.value", segments);
    setSelectedCustomerSegments(data);
  };

  const handleSpecificCustomers = (data, setFieldValue) => {
    const customers = data.map((el) => el.value);
    setFieldValue("customerEligibility.value", customers);
    setSelectSpecificCustomer(data);
  };

  const handleCheckboxChange = (setFieldValue, values, name) => {
    const newCombinations = values.combinations.includes(name)
      ? values.combinations.filter((item) => item !== name)
      : [...values.combinations, name];
    setFieldValue("combinations", newCombinations);
  };

  // const handleCombineDateTime = (date, time) => {
  //   if (!date || !time) return '';
  //   const dateTimeString = `${date}T${time}:00.000`;
  //   // const formattedDateTime = moment(dateTimeString, 'YYYY-MM-DDTHH:mm:ss:SSSZZ').format('YYYY-MM-DDTHH:mm:ssZ');
  //   return dateTimeString;
  // };

  const handleCombineDateTime = (date, time) => {
    if (!date || !time) return "";

    // Ensure the time is in HH:mm:ss format (append seconds if missing)
    const timeParts = time.split(":");
    if (timeParts.length === 2) {
      timeParts.push("00"); // Add seconds if not provided
    }
    const formattedTime = timeParts.join(":");

    // Combine date and time into an ISO 8601 string
    const dateTimeString = `${date}T${formattedTime}.000Z`;

    return dateTimeString;
  };

  const handleRadioChange = (setFieldValue, fieldName) => {
    setFieldValue(
      "atDiscountValue.percentage.checked",
      fieldName === "percentage"
    );
    setFieldValue(
      "atDiscountValue.amtOffEach.checked",
      fieldName === "amtOffEach"
    );
    setFieldValue("atDiscountValue.Free", fieldName === "Free");
  };

  const handleSubmit = (values) => {
    const {
      discountValue,
      minPurchaseRequirements,
      onlyApplydiscountOncePerOrder,
      countries,
      activeStartDate,
      activeStartTime,
      activeEndDate,
      activeEndTime,
      setEndDate,
      type,
      name,
      method,
      customerBuys,
      customerGets,
      atDiscountValue,
      maxNumberOfUse,
      customerEligibility,
      maxDiscountUses,
      combinations,
      termnconditions,
      isInfluencer,
      ...rest
    } = values;

    // Merge activeStartDate and activeStartTime into a single field
    const fixedStartDateTime = handleCombineDateTime(
      values.activeDates.startDate,
      values.startTime
    );
    const fixedEndDateTime = handleCombineDateTime(
      values.activeDates.endDate,
      values.endTime
    );
   
    const activeDates = {
      startDate: fixedStartDateTime,
      endDate: fixedEndDateTime,
    };
    // const activeDates = {
    //   startDate: activeStartDate ? new Date(`${activeStartDate}T${activeStartTime}`).toISOString() : null,
    //   endDate: setEndDate && activeEndDate ? new Date(`${activeEndDate}T${activeEndTime}`).toISOString() : null,
    // };

    const submissionDataAmountOffProducts = {
      type,
      name,
      method,
      name,
      discountValue,
      minPurchaseRequirements,
      customerEligibility,
      maxDiscountUses,
      combinations,
      activeDates,
      termnconditions,
      isInfluencer
    };

    const submissionDataBuyXGetY = {
      type,
      name,
      method,
      customerBuys,
      customerGets,
      atDiscountValue,
      maxNumberOfUse,
      customerEligibility,
      maxDiscountUses,
      combinations,
      activeDates,
      termnconditions,
      isInfluencer
    };

    const submissionDataAmountOffOrders = {
      type,
      name,
      method,
      discountValue,
      minPurchaseRequirements,
      customerEligibility,
      maxDiscountUses,
      combinations,
      activeDates,
      termnconditions,
      isInfluencer
    };

    const submissionDataFreeShipping = {
      type,
      name,
      method,
      minPurchaseRequirements,
      // countries,
      customerEligibility,
      maxDiscountUses,
      combinations,
      activeDates,
      termnconditions,
      isInfluencer
    };
    const submissionData =
      location?.state === "amountOffProducts"
        ? submissionDataAmountOffProducts
        : location?.state === "amountOffOrder"
        ? submissionDataAmountOffOrders
        : location?.state === "buyXgetY"
        ? submissionDataBuyXGetY
        : submissionDataFreeShipping;
    couponId.get("_id")
      ? dispatch(updateAdminCouponAction(couponId.get("_id"), submissionData))
      : dispatch(createCouponAction(submissionData));

  };


  return (
    <PageLayout>
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={CouponSchema}
        onSubmit={(e, values) => {
          handleSubmit(e, values);
        }}
      >
        {({ values, handleChange, handleBlur, setFieldValue }) => (
    
          <Form>
            <Row>
              <Col xl={12}>
                <CardLayout>
                  <Breadcrumb title={"Create Product Discount"}>
                    {/* {data?.breadcrumb.map((item, index) => (
                                <li key={ index } className="mc-breadcrumb-item">
                                    {item.path ? <Anchor className="mc-breadcrumb-link" href={ item.path }>{ item.text }</Anchor> : item.text }
                                </li>
                            ))} */}
                  </Breadcrumb>
                </CardLayout>
              </Col>
              <Col xl={7}>
                <CardLayout>
                  <CardHeader
                    title={`${
                      location?.state === "amountOffProducts"
                        ? "Amount Of Products"
                        : location?.state === "buyXgetY"
                        ? "Buy X get Y"
                        : location?.state === "amountOffOrder"
                        ? "Amount Off Order"
                        : "Free Shipping"
                    }`}
                  />

                  <Row>
                    <BootstrapForm.Group>
                      <BootstrapForm.Label>Method</BootstrapForm.Label>
                      <div>
                        <BootstrapForm.Check
                          inline
                          type="radio"
                          name="method.name"
                          label="Discount code"
                          value="discountCode"
                          checked={values.method.name === "discountCode"}
                          onChange={handleChange}
                        />
                        <BootstrapForm.Check
                          disabled
                          inline
                          type="radio"
                          name="method.name"
                          label="Automatic discount"
                          value="automaticDiscount"
                          checked={values.method.name === "automaticDiscount"}
                          onChange={handleChange}
                        />
                      </div>
                      {values?.method?.name === "discountCode" ? (
                        <>
                          <BootstrapForm.Group>
                            <div className="d-flex justify-content-between mt-2">
                              <BootstrapForm.Label>
                                Discount code
                              </BootstrapForm.Label>
                              <Button
                                onClick={(e) => {
                                  handleChangeCode(e, setFieldValue);
                                }}
                                style={{ color: "#005BD3" }}
                              >
                                Generate Random Code
                              </Button>
                            </div>
                            <Field
                              as={BootstrapForm.Control}
                              // onChange={(e)=>handleChangeCode(e,setFieldValue)}
                              name="method.discountCode"
                              type="text"
                              placeholder="Enter discount code"
                            />
                          </BootstrapForm.Group>
                          <BootstrapForm.Group className="mt-2">
                            <BootstrapForm.Label>
                              Discount Title
                            </BootstrapForm.Label>
                            <Field
                              as={BootstrapForm.Control}
                              name="name"
                              type="text"
                              placeholder="Enter discount title"
                            />
                          </BootstrapForm.Group>
                        </>
                      ) : (
                        <BootstrapForm.Group>
                          {" "}
                          <BootstrapForm.Label>
                            Discount Title
                          </BootstrapForm.Label>
                          <Field
                            as={BootstrapForm.Control}
                            name="method.title"
                            type="text"
                            placeholder="Enter discount title"
                          />
                        </BootstrapForm.Group>
                      )}

                      {/* Discount Value */}

                      {(location?.state === "amountOffProducts" ||
                        location?.state === "amountOffOrder") && (
                        <>
                          {" "}
                          <BootstrapForm.Label className="mt-3">
                            Discount Value
                          </BootstrapForm.Label>
                          <Row>
                            <Col sm={6}>
                              <Field
                                as="select"
                                name="discountValue.type"
                                className="form-control"
                              >
                                <option value="fixedAmount">
                                  Fixed amount
                                </option>
                                <option value="percentage">Percentage</option>
                              </Field>
                            </Col>
                            <Col sm={6}>
                              <Field
                                as={BootstrapForm.Control}
                                name={
                                  values?.discountValue?.type === "fixedAmount"
                                    ? "discountValue.amount"
                                    : "discountValue.percentage"
                                }
                                type="number"
                                placeholder={
                                  values?.discountValue?.type === "percentage"
                                    ? "Enter discount %"
                                    : "Enter fixed amount"
                                }
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </BootstrapForm.Group>

                    {/* Buy X Get Y Type */}

                    {/* Customer buys section */}

                    {location?.state === "buyXgetY" && (
                      <>
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>
                            Customer buys
                          </BootstrapForm.Label>
                          <div className="d-flex">
                            <Field
                              type="radio"
                              name="customerBuys.name"
                              value="minQuantityOfItem"
                              as={BootstrapForm.Check}
                              checked={
                                values?.customerBuys?.name ===
                                "minQuantityOfItem"
                              }
                              inline
                              label="Minimum quantity of items"
                            />
                            <Field
                              type="radio"
                              name="customerBuys.name"
                              value="‘minPurchaseAmt’"
                              as={BootstrapForm.Check}
                              inline
                              label="Minimum purchase amount"
                            />
                          </div>
                          <div className="d-flex gap-4">
                            <Field
                              type="number"
                              name={"customerBuys.value"}
                              onChange={handleChange}
                              as={BootstrapForm.Control}
                              placeholder={
                                values?.customerBuys?.name ===
                                "minQuantityOfItem"
                                  ? "Quantity"
                                  : "Amount"
                              }
                            />
                            <Field
                              as="select"
                              name="customerBuys.anyItemsFrom"
                              className="form-control ml-2"
                            >
                              <option value="specificProducts">
                                Specific products
                              </option>
                              <option value="specificCollections">
                                Specific collections
                              </option>
                            </Field>
                          </div>
                          {/* <div className="d-flex mt-2">
                      <Field
                        type="text"
                        name="customerBuysSearch"
                        as={BootstrapForm.Control}
                        placeholder="Search products"
                      />
                    </div> */}
                          <div className=" mt-2">
                            <LabelField
                              type="multi_select"
                              name={
                                values?.customerBuys?.anyItemsFrom ===
                                "specificProducts"
                                  ? "customerBuys.products"
                                  : "customerBuys.collections"
                              }
                              label={
                                values?.customerBuys?.anyItemsFrom ===
                                "specificProducts"
                                  ? "Products"
                                  : "Collections"
                              }
                              placeholder={
                                values?.customerBuys?.anyItemsFrom ===
                                "specificProducts"
                                  ? "Search Products"
                                  : "Search Collections"
                              }
                              closeMenuOnSelect={false}
                              options={
                                values?.customerBuys?.anyItemsFrom ===
                                "specificProducts"
                                  ? productsListing
                                  : proCategory
                              }
                              onChange={(e) => {
                                values?.customerBuys?.anyItemsFrom ===
                                "specificProducts"
                                  ? handleSelectProductsCustomerBuys(
                                      e,
                                      setFieldValue
                                    )
                                  : handleSelectCollectionsCustomerBuys(
                                      e,
                                      setFieldValue
                                    );
                              }}
                              value={
                                values?.customerBuys?.anyItemsFrom ===
                                "specificProducts"
                                  ? selectedProductsCustomerBuys
                                  : selectedCollectionCustomerBuys
                              }
                              fieldSize="w-100 h-md"
                            />
                          </div>
                        </BootstrapForm.Group>

                        {/* Customer gets section */}

                        <BootstrapForm.Group>
                          <BootstrapForm.Label>
                            Customer gets
                          </BootstrapForm.Label>
                          <div className="d-flex gap-4">
                            <Field
                              type="number"
                              name="customerGets.quantity"
                              as={BootstrapForm.Control}
                              onChange={handleChange}
                              placeholder="Quantity"
                            />
                            <Field
                              as="select"
                              name="customerGets.anyItemsFrom"
                              className="form-control ml-2"
                            >
                              <option value="specificProducts">
                                Specific products
                              </option>
                              <option value="specificCollections">
                                Specific collections
                              </option>
                            </Field>
                          </div>
                          {/* <div className="d-flex mt-2">
                      <Field
                        type="text"
                        name="customerGetsSearch"
                        as={BootstrapForm.Control}
                        placeholder="Search products"
                      />
                    </div> */}
                          <div className=" mt-2">
                            <LabelField
                              type="multi_select"
                              name={
                                values?.customerGets?.anyItemsFrom ===
                                "specificProducts"
                                  ? "customerGets.products"
                                  : "customerGets.collections"
                              }
                              label={
                                values?.customerGets?.anyItemsFrom ===
                                "specificProducts"
                                  ? "Products"
                                  : "Collections"
                              }
                              placeholder={
                                values?.customerGets?.anyItemsFrom ===
                                "specificProducts"
                                  ? "Search Products"
                                  : "Search Collections"
                              }
                              closeMenuOnSelect={false}
                              options={
                                values?.customerGets?.anyItemsFrom ===
                                "specificProducts"
                                  ? productsListing
                                  : proCategory
                              }
                              onChange={(e) => {
                                values?.customerGets?.anyItemsFrom ===
                                "specificProducts"
                                  ? handleSelectProductsCustomerGets(
                                      e,
                                      setFieldValue
                                    )
                                  : handleSelectCollectionsCustomerGets(
                                      e,
                                      setFieldValue
                                    );
                              }}
                              value={
                                values?.customerGets?.anyItemsFrom ===
                                "specificProducts"
                                  ? selectedProductsCustomerGets
                                  : selectedCollectionsCustomerGets
                              }
                              fieldSize="w-100 h-md"
                            />
                          </div>
                        </BootstrapForm.Group>

                        {/* Discounted value section */}
                        <>
                          {/* <BootstrapForm.Group>
                    <BootstrapForm.Label>At a discounted value</BootstrapForm.Label>
                    <div>
                      <Field
                        type="radio"
                        name="atDiscountValue.percentage.checked"
                        checked={values?.atDiscountValue?.percentage?.checked}
                        onChange={handleChange}
                        value={values?.atDiscountValue?.percentage?.checked && true}
                        as={BootstrapForm.Check}
                        inline
                        label="Percentage"
                      />
                      <Field
                        type="radio"
                        name="atDiscountValue.amtOffEach.checked"
                        onChange={handleChange}
                        checked={values?.atDiscountValue?.amtOffEach?.checked}
                        value={values?.atDiscountValue?.amtOffEach?.checked && true}
                        as={BootstrapForm.Check}
                       
                        inline
                        label="Amount off each"
                      />
                      <Field
                        type="radio"
                        name="atDiscountValue.Free"
                        onChange={handleChange}
                        checked={values?.atDiscountValue?.Free}
                        as={BootstrapForm.Check}
                        
                        inline
                        label="Free"
                      />
                    </div>
                    {values?.atDiscountValue?.percentage?.checked && (
                      <Field
                        type="number"
                        name="atDiscountValue.percentage.value"
                        as={BootstrapForm.Control}
                        onChange={handleChange}
                        placeholder="%"
                      />
                    )}
                    {values?.atDiscountValue?.amtOffEach?.checked && (
                      <Field
                        type="number"
                        name="atDiscountValue.amtOffEach.value"
                        as={BootstrapForm.Control}
                        onChange={handleChange}
                        placeholder="Amount off each"
                      />
                    )}
                  </BootstrapForm.Group> */}

                          <BootstrapForm.Group>
                            <BootstrapForm.Label>
                              At a discounted value
                            </BootstrapForm.Label>
                            <div>
                              <Field
                                type="radio"
                                name="atDiscountValue.percentage.checked"
                                checked={
                                  values?.atDiscountValue?.percentage?.checked
                                }
                                onChange={() =>
                                  handleRadioChange(setFieldValue, "percentage")
                                }
                                as={BootstrapForm.Check}
                                inline
                                label="Percentage"
                              />
                              <Field
                                type="radio"
                                name="atDiscountValue.amtOffEach.checked"
                                onChange={() =>
                                  handleRadioChange(setFieldValue, "amtOffEach")
                                }
                                checked={
                                  values?.atDiscountValue?.amtOffEach?.checked
                                }
                                as={BootstrapForm.Check}
                                inline
                                label="Amount off each"
                              />
                              <Field
                                type="radio"
                                name="atDiscountValue.Free"
                                onChange={() =>
                                  handleRadioChange(setFieldValue, "Free")
                                }
                                checked={values?.atDiscountValue?.Free}
                                as={BootstrapForm.Check}
                                inline
                                label="Free"
                              />
                            </div>
                            {values?.atDiscountValue?.percentage?.checked && (
                              <Field
                                type="number"
                                name="atDiscountValue.percentage.value"
                                as={BootstrapForm.Control}
                                placeholder="%"
                              />
                            )}
                            {values?.atDiscountValue?.amtOffEach?.checked && (
                              <Field
                                type="number"
                                name="atDiscountValue.amtOffEach.value"
                                as={BootstrapForm.Control}
                                placeholder="Amount off each"
                              />
                            )}
                          </BootstrapForm.Group>

                          <BootstrapForm.Group>
                            <BootstrapForm.Check
                              type="checkbox"
                              name="maxNumberOfUse.checked"
                              label="Set a maximum number of uses per order"
                              checked={values?.maxNumberOfUse?.checked}
                              onChange={handleChange}
                            />
                            {values?.maxNumberOfUse?.checked && (
                              <Field
                                type="number"
                                name="maxNumberOfUse.value"
                                as={BootstrapForm.Control}
                                placeholder=""
                              />
                            )}
                          </BootstrapForm.Group>
                        </>
                      </>
                    )}

                    {/* Applies To */}
                    {location?.state === "amountOffProducts" && (
                      <>
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>Applies to</BootstrapForm.Label>
                          <Field
                            as="select"
                            name="discountValue.appliesTo"
                            className="form-control"
                          >
                            <option value="specificCollections">
                              Specific collections
                            </option>
                            <option value="specificProducts">
                              All products
                            </option>
                          </Field>
                          {/* <LabelField
                          type="multi_select"
                          name="productCategory"
                          label="Products"
                        //   options={productsList}
                        //   onChange={(e) =>
                        //     handleSelectProduct(e, formik.setFieldValue)
                        //   }
                        //   value={selectedProducts}
                          fieldSize="w-100 h-md"
                        /> */}
                          <div style={{ marginTop: "10px" }}>
                            <LabelField
                              type="multi_select"
                              name={
                                values?.discountValue?.appliesTo ===
                                "specificProducts"
                                  ? "discountValue.products"
                                  : "discountValue.collections"
                              }
                              label={
                                values?.discountValue?.appliesTo ===
                                "specificProducts"
                                  ? "Products"
                                  : "Collections"
                              }
                              placeholder={
                                values?.discountValue?.appliesTo ===
                                "specificProducts"
                                  ? "Search Products"
                                  : "Search Collections"
                              }
                              closeMenuOnSelect={false}
                              options={
                                values?.discountValue?.appliesTo ===
                                "specificProducts"
                                  ? productsListing
                                  : proCategory
                              }
                              onChange={(e) => {
                                values?.discountValue?.appliesTo ===
                                "specificProducts"
                                  ? handleSelectProducts(e, setFieldValue)
                                  : handleSelectCollections(e, setFieldValue);
                              }}
                              value={
                                values?.discountValue?.appliesTo ===
                                "specificProducts"
                                  ? selectedProducts
                                  : selectedCollections
                              }
                              fieldSize="w-100 h-md"
                            />
                          </div>
                          {/* <div className="position-relative"> 
                        <i class="fa fa-search position-absolute" style={{top:'31%' , right:"13px" }}></i>
                  <Field
                    as={BootstrapForm.Control}
                    name="collections"
                    type="text"
                    placeholder={values?.appliesTo === "Specific collections" ? "Search Collections" : "Search Products"}
                    className="mt-2"
                  /></div> */}
                        </BootstrapForm.Group>
                      </>
                    )}

                    {/* Minmum Purchase Requirements */}
                    {location?.state !== "buyXgetY" && (
                      <>
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>
                            Minimum purchase requirements
                          </BootstrapForm.Label>
                          <div>
                            <BootstrapForm.Check
                              type="radio"
                              name="minPurchaseRequirements.name"
                              label="No minimum requirements"
                              value="noMinReq"
                              checked={
                                values?.minPurchaseRequirements?.name ===
                                "noMinReq"
                              }
                              onChange={handleChange}
                            />
                            <BootstrapForm.Check
                              type="radio"
                              name="minPurchaseRequirements.name"
                              label="Minimum purchase amount (₹)"
                              value="minPurchaseAmt"
                              checked={
                                values?.minPurchaseRequirements?.name ===
                                "minPurchaseAmt"
                              }
                              onChange={handleChange}
                            />
                            <BootstrapForm.Check
                              type="radio"
                              name="minPurchaseRequirements.name"
                              label="Minimum quantity of items"
                              value="minQuantityOfItem"
                              checked={
                                values?.minPurchaseRequirements?.name ===
                                "minQuantityOfItem"
                              }
                              onChange={handleChange}
                            />
                          </div>
                          {values?.minPurchaseRequirements?.name ===
                            "minPurchaseAmt" && (
                            <Field
                              as={BootstrapForm.Control}
                              name="minPurchaseRequirements.value"
                              type="number"
                              placeholder="Enter value"
                              className="mt-2"
                            />
                          )}
                          {values?.minPurchaseRequirements?.name ===
                            "minQuantityOfItem" && (
                            <Field
                              as={BootstrapForm.Control}
                              name="minPurchaseRequirements.value"
                              type="number"
                              placeholder="Enter value"
                              className="mt-2"
                            />
                          )}
                        </BootstrapForm.Group>
                      </>
                    )}

                    {/* Customer Eligibility */}

                    {values?.method?.name === "discountCode" && (
                      <BootstrapForm.Group>
                        <BootstrapForm.Label>
                          Customer eligibility
                        </BootstrapForm.Label>
                        <div>
                          <BootstrapForm.Check
                            type="radio"
                            name="customerEligibility.name"
                            label="All customers"
                            value="allCustomers"
                            checked={
                              values.customerEligibility.name === "allCustomers"
                            }
                            onChange={handleChange}
                          />
                          <BootstrapForm.Check
                            type="radio"
                            name="customerEligibility.name"
                            label="Specific customer segments"
                            value="specificCustomerSegment"
                            checked={
                              values.customerEligibility.name ===
                              "specificCustomerSegment"
                            }
                            onChange={handleChange}
                          />
                          {values.customerEligibility.name ===
                            "specificCustomerSegment" && (
                            <div style={{ marginTop: "10px" }}>
                              <LabelField
                                type="multi_select"
                                name="customerEligibility.value"
                                placeholder={"Search Customer Segments"}
                                closeMenuOnSelect={false}
                                options={customerSegmentsList}
                                onChange={(e) =>
                                  handleSelectCustomerSegments(e, setFieldValue)
                                }
                                value={selectedCustomerSegments}
                                fieldSize="w-100 h-md"
                              />
                            </div>
                          )}
                          <BootstrapForm.Check
                            type="radio"
                            name="customerEligibility.name"
                            label="Specific customers"
                            value="specificCustomer"
                            checked={
                              values.customerEligibility.name ===
                              "specificCustomer"
                            }
                            onChange={handleChange}
                          />
                          {values.customerEligibility.name ===
                            "specificCustomer" && (
                            <div style={{ marginTop: "10px" }}>
                              <LabelField
                                type="multi_select"
                                name="customerEligibility.value"
                                placeholder={"Search Customers"}
                                closeMenuOnSelect={false}
                                options={customersList}
                                onChange={(e) =>
                                  handleSpecificCustomers(e, setFieldValue)
                                }
                                value={specificCustomer}
                                fieldSize="w-100 h-md"
                              />
                            </div>
                          )}
                        </div>
                      </BootstrapForm.Group>
                    )}

                    {/* Maximum Discount Uses(Limit) */}
                    {values?.method?.name === "discountCode" && (
                      <BootstrapForm.Group>
                        <BootstrapForm.Label>
                          Maximum discount uses
                        </BootstrapForm.Label>
                        <BootstrapForm.Check
                          type="checkbox"
                          name="maxDiscountUses.limitNumberOfTimesThisDiscountCanBeUsed.checked"
                          label="Limit number of times this discount can be used in total"
                          checked={
                            values.maxDiscountUses
                              .limitNumberOfTimesThisDiscountCanBeUsed.checked
                          }
                          onChange={handleChange}
                        />
                        {values.maxDiscountUses
                          .limitNumberOfTimesThisDiscountCanBeUsed.checked && (
                          <Field
                            as={BootstrapForm.Control}
                            name="maxDiscountUses.limitNumberOfTimesThisDiscountCanBeUsed.value"
                            type="number"
                            placeholder="Enter value"
                            className="mt-2"
                          />
                        )}
                         <BootstrapForm.Check
                          type="checkbox"
                          name="maxDiscountUses.limitToOneUsePerCustomer.checked"
                          label="Limit to one use per customer"
                          checked={
                            values.maxDiscountUses
                              .limitToOneUsePerCustomer.checked
                          }
                          onChange={handleChange}
                        />
                      </BootstrapForm.Group>
                    )}

                    {/* Combinations */}
                    <BootstrapForm.Group>
                      <BootstrapForm.Label>Combinations</BootstrapForm.Label>
                      {values.discountCode && (
                        <p>
                          <strong>{values.discountCode}</strong> can combine
                          with
                        </p>
                      )}
                      <BootstrapForm.Check
                        type="checkbox"
                        name="combinations.productDiscount"
                        label="Product discounts"
                        checked={values.combinations.includes(
                          "productDiscount"
                        )}
                        onChange={() =>
                          handleCheckboxChange(
                            setFieldValue,
                            values,
                            "productDiscount"
                          )
                        }
                      />
                      <BootstrapForm.Check
                        type="checkbox"
                        name="combinations.orderDiscount"
                        label="Order discounts"
                        checked={values.combinations.includes("orderDiscount")}
                        onChange={() =>
                          handleCheckboxChange(
                            setFieldValue,
                            values,
                            "orderDiscount"
                          )
                        }
                      />
                      <BootstrapForm.Check
                        type="checkbox"
                        name="combinations.shippingDiscount"
                        label="Shipping discounts"
                        checked={values.combinations.includes(
                          "shippingDiscount"
                        )}
                        onChange={() =>
                          handleCheckboxChange(
                            setFieldValue,
                            values,
                            "shippingDiscount"
                          )
                        }
                      />
                    </BootstrapForm.Group>

                    {/* Active Dates */}
                    <BootstrapForm.Group>
                      <BootstrapForm.Label>Active dates</BootstrapForm.Label>
                      <Row>
                        <Col sm={6}>
                          <BootstrapForm.Label className="me-2">
                            Start Date
                          </BootstrapForm.Label>
                          <Field
                            as={BootstrapForm.Control}
                            type="date"
                            name="activeDates.startDate"
                            value={values?.activeDates?.startDate}
                            onChange={handleChange}
                            // onBlur={handleBlur}
                          />
                        </Col>
                        <Col sm={6}>
                          <BootstrapForm.Label className="me-2">
                            Start time
                          </BootstrapForm.Label>
                          <Field
                            as={BootstrapForm.Control}
                            type="time"
                            name="startTime"
                            value={values?.startTime}
                            onChange={handleChange}
                            // onBlur={handleBlur}
                          />
                        </Col>
                      </Row>
                    </BootstrapForm.Group>

                    {/* Set End Dates */}
                    <BootstrapForm.Group>
                      <Field
                        type="checkbox"
                        name="setEndDate"
                        as={BootstrapForm.Check}
                        inline
                        label="Set end date"
                        onChange={() =>
                          setFieldValue("setEndDate", !values.setEndDate)
                        }
                      />
                    </BootstrapForm.Group>

                    {values.setEndDate && (
                      <BootstrapForm.Group>
                        {/* <BootstrapForm.Label>Active dates</BootstrapForm.Label> */}
                        <Row className="d-flex flex-nowrap">
                          <Col sm={6}>
                            <BootstrapForm.Label className="mt-2">
                              End Date
                            </BootstrapForm.Label>
                            <Field
                              as={BootstrapForm.Control}
                              name="activeDates.endDate"
                              value={values?.activeDates.endDate}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              type="date"
                            />
                          </Col>
                          .
                          <Col sm={6}>
                            <BootstrapForm.Label className="mt-2">
                              End Time
                            </BootstrapForm.Label>
                            <Field
                              as={BootstrapForm.Control}
                              name="endTime"
                              value={values?.endTime}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              type="time"
                            />
                          </Col>
                        </Row>
                      </BootstrapForm.Group>
                    )}
                    {/* <BootstrapForm.Group>
                    <BootstrapForm.Label>Active dates</BootstrapForm.Label>
                   
                    <Row>
                    <Col sm={6}>
                      <Field
                        type="date"
                        name="activeStartDate"
                        as={BootstrapForm.Control}
                      />
                      <Field
                        type="time"
                        name="activeStartTime"
                        as={BootstrapForm.Control}
                      />
                    </Col>
                    </Row>
                    <Row>
                      <Field
                        type="checkbox"
                        name="setEndDate"
                        as={BootstrapForm.Check}
                        inline
                        label="Set end date"
                        onChange={() => setFieldValue('setEndDate', !values.setEndDate)}
                      />
                      {values.setEndDate && (
                        <Row>
                        <Col sm={6}>
                          <Field
                            type="date"
                            name="activeEndDate"
                            as={BootstrapForm.Control}
                          />
                          <Field
                            type="time"
                            name="activeEndTime"
                            as={BootstrapForm.Control}
                          />
                        </Col>
                        </Row>
                      )}
                    </Row>
                  </BootstrapForm.Group> */}
                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Term & Conditions</BootstrapForm.Label>
                   
                    <Row>
                    <Col sm={12}>
                     {/* <JoditEditor
                    label="Terms & Conditions"
                    name="termnconditions"
                    value={values.termnconditions}
                    fieldSize="w-100 h-text-md"
                    onChange={handleChange}
                    /> */}
                     <Field name="termnconditions">
    {({ field, form }) => (
      <FormikJoditEditor field={field} form={form} />
    )}
  </Field>
                    </Col>
                    </Row>
                  </BootstrapForm.Group> 
                  <BootstrapForm.Group>
      <BootstrapForm.Label>
        Do you want to use this coupon as an influencer coupon?
      </BootstrapForm.Label>
      <Field
        as="select"
        name="isInfluencer"
        className="form-select"
        onChange={(e) => {
          const selectedValue = e.target.value === "true"; // Convert string to boolean
          setFieldValue("isInfluencer", selectedValue); // Update Formik state
          setIsInfluencer(selectedValue); // Update local state
        }}
      >
        <option value="">Select an option</option>
        <option value="true">Yes, use this as an influencer coupon</option>
        <option value="false">No, do not use this as an influencer coupon</option>
      </Field>
    </BootstrapForm.Group>
                  </Row>
                </CardLayout>
              </Col>
              <Col xl={5}>
                <CardLayout className="mb-4">
                  <CardHeader title="Summary" />
                  <Row>
                    <Col xl={12}>
                      <Summary location={location} values={values} />
                    </Col>
                  </Row>
                </CardLayout>
              </Col>
              <Col xl={7} className="d-flex justify-content-center gap-4 ">
                <Button
                  className="btn btn-danger"
                  text="Discard"
                  onClick={() => handleNavigate()}
                />
                <Button
                  className="mc-btn primary"
                  text={couponId.get("_id") ? "Update" : "Submit"}
                  type="submit"
                  icon="cloud_upload"
                  href="#"
                />
              </Col>
            </Row>{" "}
          </Form>
        )}
      </Formik>
    </PageLayout>
  );
}
